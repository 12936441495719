body {
    overflow-y: auto;
}
#gifImage.paused {
    animation-play-state: paused;
  }
  .dropdown-menu1 {
    position: absolute;
    width: 200px;
    left: 1300px;
    top: 60px; /* Adjust according to your navbar height */
    right: 10px; /* Adjust according to your layout */
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 10px;
}

.dropdown-menu1 button {
    background: none;
    border: none;
    padding: 10px;
    text-align: left;
    width: 100%;
    cursor: pointer;
}

.dropdown-menu1 button:hover {
    background-color: #f1f1f1;
}
.sidebar {
    width: 250px;
    
    background: rgb(44, 62, 80);
    color: rgb(243, 156, 18);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .sidebar-header {
    color: rgb(243, 156, 18);
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .sidebar-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sidebar-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .action-button {
    background: none;
    border: none;
    color: rgb(243, 156, 18);
    text-align: left;
    width: 100%;
    padding: 10px;
    cursor: pointer;
  }
  
  .action-button:hover {
    background: rgb(243, 156, 18);
    color: rgb(44, 62, 80);
  }
  
  .profile-button {
    background: none;
    border: none;
    color: rgb(243, 156, 18);
    cursor: pointer;
    text-align: center;
    margin-top: 10px;
  }
  .tempDiv{
    width:25%;
    margin-top:-52px;
  }
  @media screen and (max-height: 600px) {
    .tempDiv{
      
      margin-top:0px;
    }
  }
  .liveevent{
    width:fit-content;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    align-items:flex-end;
    height:60vh;
    background-color: rgba(238, 238, 238, 0.933);
    padding:10px;
    border-radius:25px;
    margin:10px;
  }
  .liveevent h5{
    display:flex;
    justify-content:space-evenly;
    align-content:flex-start;
    margin-top:12px;
    justify-content:space-between;
    width:100%;
    padding-left:12px;
      
  }
  .camerafeed{
    display: flex;
    height: 60vh;
    flex-direction: column;
    justify-content: flex-start;
    width:fit-content;
    align-items:center;
    margin:10px;
    background-color: rgba(238, 238, 238, 0.933);
    padding:10px;
    border-radius:25px;
    }