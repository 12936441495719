/* LandingPage.css */

body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.header {
    background: #2c3e50;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .logo {
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
}

.navbar .nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.navbar .nav-links li {
    margin-left: 20px;
}

.navbar .nav-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1em;
}

.hero {
    position: relative;
    text-align: center;
    color: white;
    padding:0;
}

.hero-image {
    width: 100%;
    height: 50vh;
    filter: blur(5px); /* Adjust the blur as needed */
}

.hero-text {
    
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        align-content: flex-start;
    
    position: absolute;
    top: 50%;
    left: 38%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
}

.hero-text h1 {
    margin: 0;
    font-size: 2.5em;
    color: black;
}

.hero-text p {
    margin: 10px 0 0;
    font-size: 1.2em;
    text-align: justify;
}

.collaborators {
    padding: 40px 20px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.collaborator-info {
    background: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.collaborator-info h2 {
    margin-top: 0;
}
.social-media-handles {
    margin-top: 10px;
}

.social-media-handles a {
    margin: 0 10px;
    color: #555; /* Change color as needed */
    text-decoration: none;
    font-size: 24px; /* Adjust size as needed */
}

.social-media-handles a:hover {
    color: #0073e6; /* Change hover color as needed */
}