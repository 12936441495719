@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;700&display=swap');



.container {
  max-width: 400px;
  min-width: 400px;
}

.title {
  font-weight: 700;
  font-size: 50px;
}

.form-rounded {
  border-radius: 2em;
}

.back-card {
  border-radius: 40px !important;
}

.city-name {
  position: absolute;
  width: 100%;
}

.city-name p {
  font-size: 16pt;
  font-weight: 400;
}

.city-name span {
  font-weight: 400;
  font-size: 36pt;
  font-family: 'Times New Roman', Times, serif;
  position: relative;
  top: -60px;
}

.temp span {
  font-weight: 100;
  font-size: 5em;
  letter-spacing: -5px;
  white-space: nowrap;
}
.card-mid {
  line-height: 0.5;
}

.condition {
  line-height: 1em;
  font-weight: 700;
  font-size: 1em;
  text-transform: capitalize;
}
.wind::before {
  content: '';
  background: url('img/up.svg') no-repeat;
  width: 10px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 3px;
}

.low::before {
  content: '';
  background: url('img/down.svg') no-repeat;
  width: 10px;
  height: 15px;
  display: inline-block;
  position: relative;
  top: 3px;
}
.icon-container {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  background: #202020;
}
.icon-container img {
  margin: auto;
}

.card-bottom {
  line-height: 0.5;
}
.card-bottom span {
  font-size: 12px;
}
.card-bottom p {
  font-size: 50px;
  font-weight: 100;
  letter-spacing: -2px;
}