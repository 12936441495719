
.header {
    background-color: #2c3e50;
    padding: 20px;
    color: #ecf0f1;
    margin-right: 30px;
    margin-left: 30px;
  }
  
  .header__title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    color: #f39c12;
    text-align: center;
  }
  .event-table {
    margin-top: 20px;
  }
    
  
  .content {
    padding: 20px;
  }
  
  .card {
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card__content {
    padding: 20px;
  }
  
  .card__title {
    margin: 0;
    color: #000;
  }
  
  .card__body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px; /* Adjust the height as needed */
  }
  
  .chart {
    width: 100%;
    height: 100%;
  }
  