/* CameraDetailsPage.css */

/* Page container */
.camera-details-page {
    display: flex;

  }
  
  /* Sidebar */
  .sidebar {
    width: 250px;
    background-color:cornflowerblue;
    color: #fff;
    padding: 20px;
    /* Add sidebar styles as needed */
  }
  
  /* Main Content */
  .main-content {
    flex: 1;
    padding: 20px;
    background: azure;
  }
  
 
  /* Chart Container */
  .chart-container {
    margin-bottom: 20px;
    /* Add chart container styles as needed */
  }
  /* TodaySale.css */
.today-sale-container {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 300px; /* Adjust the width as needed */
  }
  
  .today-sale-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .today-sale-info {
    display: flex;
    width: 30%;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
    background: antiquewhite;
    border-radius: 4px;
  }
  
  .info-label {
    font-weight: bold;
    color: #333;
  }
  
  .info-value {
    color: #555;
  }
  .today-sale-details{
    display: flex;
    justify-content: space-around;
  }